const translation = {
  buttons: {
    home: "首页",
    contact: "联系我们",
    about: "关于",
    testItForFree: "免费试用",
    howItWorks: "它是如何工作的",
    allBenefits: "所有好处",
    seeOurCustomerList: "查看客户列表",
    submit: "提交",
  },
};

export default translation;
