const translation = {
  testItForFree: {
    testItForFreeTitle: "免费试用",
    testItForFreeText1:
      "为了让您的棉纺织厂能够实际了解软件的所有功能，并确信它可以帮助您，我们提供了",
    testItForFreeText1Bold: "OptCotton 的两个月免费试用。",
    testItForFreeText2:
      "在此试用期间，您将无任何限制地访问软件的所有功能，包括由专业团队提供的协助和监督。",
    testItForFreeText3:
      "这次免费试用的目的是让您评估 OptCotton 并证明其在混合过程中的效果和安全性。此外，您将能够熟悉界面并更好地理解软件的工作原理。",
    disclaimerTitle: "免责声明",
    disclaimerText1: "试用期仅适用于",
    disclaimerTextBold: "01 (一个) 生产线（吹棉房）",
    disclaimerText2:
      "并将受限于 WW SYSTEM 自己的内部规定和可用性，尊重安装的时间顺序。在为期 02 个月的试用期间，我们的支持团队将全天候为您提供培训、指导和技术支持。",

    requirementsTitle: "技术要求：",
    requirement1:
      "至少运行 Windows 07 或更高版本的台式电脑/笔记本电脑，内存至少为 1GB，硬盘至少为 100GB。",
    requirement2: "安装以进行远程访问。",
    requirement3: "随时上网。",
    requirement4:
      "数据库服务器。可以使用现有的 MSSQL Server 或 Oracle 数据库实例。如果公司在其基础设施中没有运行任何数据库实例，我们可以安装 MSSQL Server 的 express 实例（免费许可证）。",
    trainingTitle: "培训",
    trainingText:
      "为了让您的团队在使用软件时感到舒适和安全，我们提供对系统主要功能和特性的培训。理想情况下，您团队中至少应有一人熟悉纤维质量参数，最好是质量经理。",
    formTitle: "有兴趣吗？回答几个问题，让我们更多地了解您的公司。",
    emailSent: "邮件已发送",
    emptyFields: "填写所有字段",
  },
};
export default translation;
