const translation = {
  howItWorks: {
    howItWorksTitle: "它是如何工作的",
    howItWorksText1:
      "是一个用于制定和控制棉花摆放的软件，控制所有 HVI 参数，以及每家公司根据最大精度创建的内部参数，而无需将库存分成类别。",
    howItWorksText2:
      "它的主要目的是消除摆放之间的质量变化，以使纱线保持其质量标准。该软件管理库存，从棉包到达到它们在开端线上的消耗。",
    howItWorksText3:
      "由一个既懂技术又懂混合工艺的团队规划和开发，该软件提供了一系列资源，可以对纱线生产过程进行全面控制。",
    steps: "简单地说，这些是摆放创建过程的步骤：",
    step1Title: "首先，定义质量！",
    step1Text:
      "混合过程始于规划。基于可用库存的质量和上一次摆放的质量，软件能够计算出下一次摆放的近似质量，只需",
    step1TextBold: "点击一下按钮。",
    step2Title: "然后，让我们从库存中取一些棉包！",
    step2Text:
      "在过程的第二步，系统确定需要从每个批次中取多少个棉包来创建一个预摆放。在仓库中，根据为每个批次定义的数量，将棉包进行物理分离以进行选择过程。",
    step2TextBold: "最容易拿到的棉包可以被选中。",
    step3Title: "接下来，让我们选择摆放的棉包。",
    step3Text:
      "通过其算法，OptCotton 分析了预摆放阶段中每个棉包的信息，并进行选择，以使摆放的平均质量尽可能接近初始阶段规划的质量。所选的棉包进一步进行排序，以使它们在开端线上均匀排列。",
    step4Title: "计划、执行和实现的过程。",
    step4Text:
      "作为从库存质量规划的混合过程的结果，通过使用人工智能，我们获得了质量均匀且稳定的摆放。",
  },
};

export default translation;
