const translation = {
  home: {
    appTitle: "最高效的包装管理软件！",
    appDescription:
      "OptCotton 控制摆放的质量，保持质量稳定，从而消除色差等问题。",

    itmaInvitation: "欢迎参观 Itma 2023",
    itmaDescription: "全球最大的国际纺织品和服装技术展览会。",
    itmaLocale: "我们将位于",
    itmaDate: "2023年6月08日 - 14日",

    howItWorks: "它是如何工作的？",
    howItWorksText:
      "从棉花进入库存的时候，OptCotton 管理整个混合过程，生成质量均匀的摆放，并为该过程带来安全性。",

    mixControlProcess: "混合过程的全面控制",
    mixControlProcessText:
      "我们的系统使用来自任何 HVI 源的所有质量参数。混合过程从可用库存的质量精确规划到包装线的棉包排列。",
    mixControlProcessImg1: "质量规划",
    mixControlProcessImg2: "棉包分离",
    mixControlProcessImg3: "棉包选择",
    mixControlProcessImg4: "棉包排列",

    benefitSlider: {
      benefitTitle1: "有效的色差控制",
      benefitText1:
        "通过使用复杂的棉花摆放算法以及减少质量差异的风险，消除了这种风险。",

      benefitTitle2: "均匀摆放",
      benefitText2:
        "为了避免质量标准的变化，OptCotton 使用库存质量和之前混合的质量，因此不会接受不可接受的变化。",

      benefitTitle3: "无需将库存分成类别",
      benefitText3: " 通过独特的方法论，无需按照质量将仓库中的棉包分成类别。",

      benefitTitle4: "对摆放中的所有 HVI 参数进行控制",
      benefitText4: " 控制所有 HVI 参数，以及每家公司所需的内部参数。",
    },

    aiTitle: "纺织行业的人工智能",
    aiText:
      "纺织行业的未来依赖于人工智能来混合棉花。通过其算法，系统能够分析和选择摆放的棉包，以稳定和安全地进行混合过程。",

    customersTitle: "我们的客户",
    customersText:
      "我们目前在美洲和亚洲的 11 个国家，已经代表了全球混纺棉花的 2%。以下是我们的一些客户。",
  },
};

export default translation;
