const translation = {
  privacyPolicy: {
    popupMessage: "本网站使用 Cookie。继续浏览即表示您同意使用 Cookie。",
    learnMore: "了解更多",
    accept: "接受",
    deny: "拒绝",
    title: "Cookie隐私政策",
    description:
      "本Cookie隐私政策描述了您访问或与optcotton.com网站进行交互时，该网站如何使用Cookie和类似技术。该政策还解释了您在使用Cookie方面的权利以及如何控制您的偏好。",
    consent: "同意",
    consentText:
      "通过使用optcotton.com网站，您同意按照本Cookie隐私政策使用Cookie。如果您不同意使用Cookie，我们建议您调整浏览器设置或停止使用optcotton.com网站。",
    cookiesType: "使用的Cookie类型",
    cookiesTypeText: "optcotton.com网站可能使用以下类型的Cookie：",
    cookiesType1:
      "基本功能Cookie：这些是网站基本功能所必需的Cookie。它们允许您浏览网站并使用基本功能，如登录帐户。",
    cookiesType2:
      "性能Cookie：这些Cookie收集有关访问者如何使用网站的信息，例如访问的页面、在网站上花费的时间以及遇到的错误。此信息用于改善网站的性能和可用性。",
    cookiesType3:
      "功能Cookie：这些Cookie允许网站记住您的偏好，例如您选择的语言或您所在的地区。这在您访问网站时提供了个性化的体验。",
    cookiesType4:
      "广告Cookie：我们可能允许第三方在optcotton.com网站上显示广告。这些Cookie根据您与网站的过去交互来收集您的兴趣信息，从而使广告商能够向您显示与您相关的广告。",
    cookiesControl: "Cookie控制",
    cookiesControlText:
      "您有权控制和管理您的Cookie偏好。您可以调整浏览器设置以阻止或删除Cookie，以及设置第三方Cookie的偏好。请注意，通过阻止或删除Cookie，optcotton.com网站的某些功能可能无法正常运行。",
    links: "链接到其他网站",
    linksText:
      "optcotton.com网站可能包含链接到其他具有其自己隐私政策的网站。我们建议您查看这些站点的隐私政策，因为我们无法控制它们的Cookie和隐私做法。",
    policyChanges: "Cookie隐私政策变更",
    policyChangesText:
      "我们保留随时对本Cookie隐私政策进行更改的权利。任何更改将立即生效，并在optcotton.com网站上发布更新的政策。",
    questions:
      "如果您对我们的Cookie隐私政策有任何疑问或顾虑，请使用optcotton.com网站上提供的联系方式与我们联系。",
    implementation: "生效日期：2023年6月23日。",
  },
};
export default translation;
