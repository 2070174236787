const translation = {
  customers: {
    customersTitle: "我们的客户",
    customersText1:
      "OptCotton 的多功能性意味着它可以在世界任何地方的任何混纺过程中使用。",
    customersText2: "我们的软件目前在 11 个国家使用：",
    customersCountry:
      "阿根廷、孟加拉国、巴西、印度、印度尼西亚、墨西哥、巴基斯坦、巴拉圭、泰国、土耳其和乌兹别克斯坦。",
    customersText3:
      "将鼠标悬停在突出显示的国家上，即可查看一些选择使用 Optcotton 迈向未来的重要大型纺织公司。",
    customersText4: "请看下面每个国家选择使用 OptCotton 迈向未来的公司：",
  },
};
export default translation;
