const translation = {
  contact: {
    contactTitle: "联系我们",
    contactText: "告诉我们如何帮助您！",
    findAgents: "找到我们的代理商之一",
    call: "给我们打电话",
    english: "英语",
    englishWorkTime: "(GMT+1) 下午1点至下午5点",
    portugueseAndSpanish: "葡萄牙语 / 西班牙语",
    portugueseWorkTime: "(GMT-3) 上午8点至下午6点",
    email: "给我们发电子邮件",
    queries: "任何查询",
    support: "支持",
    location: "我们的位置",
  },
};

export default translation;
