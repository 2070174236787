const translation = {
  benefits: {
    benefitsTitle: "使用 OptCotton 的好处",
    benefitsText1: "人工智能在人们和企业的日常生活中越来越普遍，",
    benefitsTextBold: "OptCotton 将人工智能引入混合过程 ",
    benefitsText2: "旨在为客户提供生产过程中的安全性、生产力和质量",

    seeMainBenefits: "查看主要好处：",

    noNeedSepareteTitle: "无需将库存分成类别",
    noNeedSepareteText:
      "这是迄今为止最创新的包装管理方法！库存分类已经过时了！",

    shadeVariationTitle: "有效控制色差",
    shadeVariationText: "通过使用复杂的棉花摆放算法以及减少质量差异的风险。",

    uniformsLaydownsTitle: "均匀摆放",
    uniformsLaydownsText:
      "为了避免质量标准的变化，OptCotton 使用库存质量和前期混合的质量，因此不会接受不可接受的变化。",

    machineryEfficientlyTitle: "机械运行更高效",
    machineryEfficientlyText:
      "OptCotton 使用智能算法来减少机械运行中的中断，从而提高纺纱过程的效率。",

    hviParametersControlledTitle: "控制所有 HVI 参数在摆放中的控制。",
    hviParametersControlledText:
      "该系统控制所有 HVI 参数，以及每家公司所需的内部参数。",

    controlInventoryTitle: "对库存、消耗和摆放质量的全面控制",
    controlInventoryText:
      "OptCotton 允许通过控制工具、报告和图表，全面控制库存的数量和质量，以及棉花消耗。",
  },
};

export default translation;
