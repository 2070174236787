const translation = {
  footer: {
    developed: "开发者:",
    strategicPartner: "战略合作伙伴:",
    footerText: "该方法、技术和智能受到美国、巴西和瑞士的版权法保护。",
    patent: "专利申请美国 10.815.589",
  },
};

export default translation;
