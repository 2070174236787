const translation = {
  form: {
    selectOption: "选择一个选项",
    question1: "您的公司是纺纱厂吗？",
    question1Options: {
      option1: "是",
      option2: "否",
    },

    question2: "您的位置在哪里？",
    question3: "您的电子邮件是什么？",
    question4: "您的直接电话联系方式是什么？",

    question5:
      "您的纺纱厂是否有 HVI 机器？比如 Uster 的？Loepfe 的？Premier 的？其他的？",
    question5Options: {
      option1: "是的，我的纺纱厂有相同类型的 HVI 机器",
      option2: "不，我们没有类似的机器。",
      option3: "虽然我们的纺纱厂有，但我们不使用。",
      option4: "不，但我们打算在不久的将来购买。",
    },

    question6: "您的纺纱厂每月棉花消耗量是多少吨？",
    question7: "您的纺纱厂有多少生产线/吹棉房（专用于棉花）？",

    question8: "您的纺纱厂是否有质量经理？",
    question8Options: { option1: "是的，有", option2: "不，没有" },

    question9: "已购买/当前棉花棉包是否已经进行了 100% 测试？",
    question9Options: {
      option1: "是的，所有棉包都已进行了测试",
      option2: "不，但大约有 50% 的棉包已经进行了测试",
      option3: "不，但大约有 30% 的棉包已经进行了测试",
      option4: "不，但有 10% 的棉包已经进行了测试",
      option5: "不，虽然没有进行任何测试，但我们计划很快开始测试。",
    },

    question10: "目前仓库中的棉包是如何堆放的？",
    question10Options: {
      option1: "按批次",
      option2: "按容器",
      option3: "按卡车",
      option4: "其他某种方法",
    },

    question11:
      "为了进行试用，我们需要一个质量经理接受我们的培训。是否有人可以接受培训？",
    question11Options: {
      option1: "是的，有一个。",
      option2: "不，没有。",
    },

    question12: "您希望我们何时开始试用？",
    question13: "请写下您的全名以及您想运行试用版本的纺纱厂的地址。",
  },
};
export default translation;
