const translation = {
  about: {
    aboutTitle: "关于我们",
    companyName: "WW 系统",
    aboutText:
      "是一家领先的公司，为棉花行业开发软件系统。我们非常关注纺织品生产中的质量。我们位于巴西南部，距离圣保罗约 2 小时的飞行时间。我们的团队由经验丰富的软件工程师组成，具有纺纱工艺的国际专业知识。",
    originTitle: "起源",
    originText1:
      "我们的历史始于 2004 年，由现任 CEO Jaison Werlich 创立公司，他已经为纺织行业开发软件，并确定了需要一种工具来使棉花混纺的过程高效而安全。从那时起，WW Sistemas 被创建，经过国际化并于 2015 年改名为 WW 系统。",
    originText2:
      "凭借全球销售和服务组织，WW 系统通过在亚洲市场的持续不断的勘探保持不断增长的销售额。战略合作伙伴关系也是在全球市场寻找新视野的一个不断的因素。目前，除了在多个国家运营的几个代理之外，WW 系统还赢得了重要的战略合作伙伴关系，例如 ",
    rieterName: "瑞特",
    rieterCountry: "（瑞士）",
    imgDescription: "WW 系统总部",
  },
};

export default translation;
